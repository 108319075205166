$buttonBackgroundColor: #ED8B00;

h5 {
  margin-bottom: 0;
}

.ais-ClearRefinements-button, .ais-RefinementList-showMore {
  background-color: $buttonBackgroundColor;
  font-weight: 500;
  margin-bottom: 10px;

  &:focus {
    background-color: $buttonBackgroundColor;
  }

  &:hover {
    background-color: #C64600;
  }
}
