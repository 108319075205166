.supplier-container {
  display: flex;
  justify-content: center;
  color: #3a4570;
  
  h3 {
    text-align: center;
  }

  .supplier {
    border: 1px solid #c4c8d8;
    border-radius: 0;
    box-shadow: 0 2px 5px 0 #e3e5ec;  
  }
}