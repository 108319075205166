h5 {
  font-size: 0.95em;
  margin-block-end: 0.5em;
}

ul.supplier-goods-services {
  font-size: 0.9em;
  
  &.use-ellipsis {
    list-style-type: none;
    padding-inline-start: 0;

    li {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }  
  }
  
  .more {
    display: block;
    margin-block-start: 0.5em;
  }
}
