@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

dd, h3 {
  @include truncate;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}
