em {
  background: cyan;
  font-style: normal;
}

.ais-HitsPerPage {
  margin-bottom: 1em;
}

.grid-container {
  display: grid;
  grid-template-columns: 250px auto;
  grid-gap: 20px;
}

.logo-container {
  margin-bottom: 1em;
}

.pagination {
  margin: 2rem auto;
  text-align: center;
}

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.searchbox {
  margin-bottom: 2rem;
}

// Hit

.ais-Hits-item {
  width: calc(33% - 1rem);
}

.hit {
  h3 {
    font-size: 18px;
    margin-top: 0;
    min-height: 50px;
    text-align: center;
    margin-block-end: 1em;
  }

  a {
    text-decoration: none;
  }
}
